import { Divider } from "antd";
 const getMinutes = (stats) => {
    return Number(stats.timeOnIce.slice(0, stats.timeOnIce.indexOf(":"))) + (Number(stats.timeOnIce.slice(stats.timeOnIce.indexOf(":") + 1)) / 60);;
};
export const fields = [
    {
        fieldName: "corsiFor",
        displayName: "Corsi For",
        calculate(stats, options = {}) {
            stats.corsi = stats.shots + stats.blocked;
        }
    },
    {
        fieldName: "evenGoalsPer60",
        displayName: "Even Goals/60",
        abbreviation: "G/60",
        calculate(stats, options = {}) {
            const evenMinutes = Number(stats.evenTimeOnIce.slice(0, stats.evenTimeOnIce.indexOf(":"))) + (Number(stats.evenTimeOnIce.slice(stats.evenTimeOnIce.indexOf(":") + 1)) / 60);
            const sixties = evenMinutes / 60;
            stats[this.fieldName] = ((stats.goals - stats.powerPlayGoals - stats.shortHandedGoals) / sixties).toFixed(1);
        }
    },
    {
        fieldName: "evenPointsPer60",
        displayName: "Even Points/60",
        abbreviation: "P/60",
        calculate(stats, options = {}) {
            const evenMinutes = Number(stats.evenTimeOnIce.slice(0, stats.evenTimeOnIce.indexOf(":"))) + (Number(stats.evenTimeOnIce.slice(stats.evenTimeOnIce.indexOf(":") + 1)) / 60);
            const sixties = evenMinutes / 60;
            stats[this.fieldName] = ((stats.points - stats.powerPlayPoints - stats.shortHandedPoints) / sixties).toFixed(1);
        }
    },
    {
        fieldName: "hitsPer60",
        displayName: "HITS/60",
        abbreviation: "HITS/60",
        calculate(stats, options = {}) {
            const minutes = getMinutes(stats);
            const sixties = minutes / 60;
            stats[this.fieldName] = (stats.hits / sixties).toFixed(1);
        }
    }
];