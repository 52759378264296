import React, { Component } from 'react';
import {List} from 'antd';
import * as playoffs from "../json-files/nhl-final-2019.json";
// import * as reg_season from "../json-files/nhl-regseason-2019.json";

const activeColour = "rgba(255,255,255,0.4)";
const inactiveColour = "rgba(0,0,0,0.3)";

class Shotchart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playoffs: playoffs['default'],
            // reg_season: reg_season['default'],
            playoffGoalLocations: [],
            selectedShot: null,
            shotDescription: "",
            homePlate: false,
            vZone: false,
            backgroundImg: <img src={require("../assets/rink-back-gray.png")} style={{ position: "relative", width: "80%", height: "auto", top: "-15px", borderRadius: "9px" }} />,
            homePlateImg: <img src={require("../assets/home-plate-zones.png")} style={{ position: "absolute", width: "80%", height: "auto",  borderRadius: "9px", zIndex: 999, top: "-13px", right: "65px" }} />,
            vZoneImg: <img src={require("../assets/v-zones.png")} style={{ position: "absolute", width: "80%", height: "auto",  borderRadius: "9px", zIndex: 999, top: "-13px", right: "65px" }} />,
        };
        this.shots = this.shots.bind(this);
    }

    componentDidMount() {
        // document.addEventListener("mousemove", (e) => { this.setState({ mouseY: e.clientY, mouseX: e.clientX }) });
        let goalLocations = [];
        this.state.playoffs.dates.forEach((date) => {
            console.log(date);
            date.games.forEach((game) => {
                console.log(game);
                if (game.scoringPlays) {
                    game.scoringPlays.forEach((play) => {
                        goalLocations.push({ coords: play.coordinates, play, game });
                    });
                }
            });

        });
        this.setState({ playoffGoalLocations: goalLocations });
    }

    getTeamColor(num) {
        var colors = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#00205B',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#006847', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

        return colors[num];
    }

    shots() {
        let circles = [];
        const ymulti = 3.4;
        const xmulti = -2.4;
        this.state.playoffGoalLocations.forEach((goal, index) => {

            const fillColour = this.getTeamColor(goal.play.team.id);

            circles.push(<circle
                onClick={() => {
                    console.log("goal: ", index, " scored by ", goal.play.players[0].player.fullName, " for - ", goal.play.team.name);
                    let desc = <div className="stat-font"><span style={{ fontSize: "26pt" }}>{goal.game.seriesSummary.gameLabel} - {goal.play.about.ordinalNum} Period</span><br />
                        <span style={{ fontSize: "14pt" }}>{goal.play.about.periodTime}</span>
                        <br />
                        <span style={{ fontSize: "18pt" }}>{goal.play.players[0].player.fullName.concat(" - ").concat(goal.play.team.name)}</span>
                        <br />
                        <span style={{ fontSize: "12pt" }}>{goal.play.result.description}</span>
                    </div>;

                    // var d = document.getElementById('shot-description');
                    // d.style.position = "absolute";
                    // d.style.left = this.state.mouseX + 'px';
                    // d.style.top = this.state.mouseY + 'px';

                    this.setState({ selectedShot: index, shotDescription: desc });
                }}
                key={index}
                cx={goal.coords.x * xmulti}
                cy={goal.coords.y * ymulti}
                r="4"
                stroke={this.state.selectedShot === index ? "red" : "black"}
                strokeWidth="2" fill={fillColour} />);
        })
        return circles;
    }


    render() {
        return (
            <div>              
                <button style={{backgroundColor: "black"}} className="rink-color-change-btn" onClick={() => {this.setState({backgroundImg: <img src={require("../assets/rink-back-dark.png")} style={{ position: "relative", width: "80%", height: "auto", top: "-15px", borderRadius: "9px" }} /> })}}></button>
                <button style={{backgroundColor: "grey"}} className="rink-color-change-btn" onClick={() => {this.setState({backgroundImg: <img src={require("../assets/rink-back-gray.png")} style={{ position: "relative", width: "80%", height: "auto", top: "-15px", borderRadius: "9px" }} />})}}></button>
                <button style={{backgroundColor: "white"}} className="rink-color-change-btn" onClick={() => {this.setState({backgroundImg: <img src={require("../assets/rink-back-white.png")} style={{ position: "relative", width: "80%", height: "auto", top: "-15px", borderRadius: "9px" }} />})}}></button>
                <div>
                    <div class="app-font" style={{color: "white"}}>Zones</div>
                    <div style={{display: "inline-block", backgroundColor: this.state.homePlate ? activeColour: inactiveColour , width: "50px", borderRadius: "15px"}}><img width={"50px"} src={require("../assets/home-plate-preview.png")}  onClick={() => {this.setState({homePlate: !this.state.homePlate})}} /></div>
                    <div style={{display: "inline-block", backgroundColor: this.state.vZone ? activeColour: inactiveColour , width: "50px", borderRadius: "15px"}}><img width={"50px"} src={require("../assets/v-preview.png")}  onClick={() => {this.setState({vZone: !this.state.vZone})}} /></div>
                </div>
                <div style={{ display: "inline-block", position: 'relative' }}>
                
                    {this.state.backgroundImg}
                    {this.state.homePlate ? this.state.homePlateImg : ""}
                    {this.state.vZone ? this.state.vZoneImg : ""}
                    
                    <svg width={200} height={160} style={{ zIndex: 1000, position: "relative", bottom: "170px", overflow: "inherit", left: "100px" }}>
                        {this.shots()}
                    </svg>
                </div>
                <div style={{position: "relative", top: "-190px"}} id="shot-description">{this.state.shotDescription}</div>               
            </div>
        );
    }
}

export default Shotchart;