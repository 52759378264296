import React, { Component } from 'react';
import { Menu, Button, Tooltip } from 'antd';
import Shotchart from './Shotchart';
import {
    RadarChartOutlined,
    BorderOuterOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import Players from './Players';

const unfinishedText = "Thanks for checking out HOCKEYSTATS! This is far from the finished site, I just wanted to let people see as I added to it. Eventually I'd like have many features and data for all NHL teams and players spanning multiple seasons, maybe even more leagues than NHL.";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedKey: "1" };
    }



    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: "40pt", color: "white" }} className="title-font">HOCKEYSTATS</div>
                <div style={{color: "darkgrey", fontSize: "8pt"}}>This site is a work in progress <Tooltip title={unfinishedText}>
                <QuestionCircleOutlined />
 </Tooltip></div>

                <br />

                <Menu
                    defaultSelectedKeys={[this.state.selectedKey]}
                    defaultOpenKeys={[]}
                    mode="horizontal"
                    style={{ lineHeight: '64px', marginBottom: "50px" }}
                    theme="dark"
                    className="topBarMenu"               
                >
                    <Menu.Item onClick={() => { this.setState({ selectedKey: "1" }) }} key="1">
                        <BorderOuterOutlined />
                        <span>Shotchart</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => { this.setState({ selectedKey: "2" }) }} key="2">
                        <RadarChartOutlined />
                        <span>Players</span>
                    </Menu.Item>
                </Menu>

                
                {this.state.selectedKey === "1" ?
                    <Shotchart style={{

                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "50%"
                    }} /> : ""}                         

                {this.state.selectedKey === "2" ?
                    <Players /> : ""}
            </div>
        );
    }
}

export default Dashboard;