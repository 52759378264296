import React, { Component } from 'react';
import { Table, Tag, Space, Button, Checkbox } from 'antd';
import { Radar } from 'react-chartjs-2';
import DataTable from 'react-data-table-component';
import _ from 'underscore';
import {fields} from "../stats/fields.js";
import * as players from "../json-files/players.json";

const radarLabels = ["Even Points/60", "Even Goals/60", "GWG", "Hits/60", "PP Goals"];

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sortable: true,
        name: 'Name',
        selector: 'name',
        cell: row => <span style={{ fontSize: "12pt" }}>{row.name}</span>,
    },
    {
        title: 'Games Played',
        dataIndex: 'gamesPlayed',
        key: 'gamesPlayed',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Games</h2>,
        selector: 'games',
    },
    {
        title:'Points',
        dataIndex: 'points',
        key: 'points',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Points</h2>,
        selector: 'points'
    },   
   
    {
        title: 'Goals',
        dataIndex: 'goals',
        key: 'goals',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Goals</h2>,
        selector: 'goals',
    },
    {
        title: 'Assists',
        dataIndex: 'assists',
        key: 'assists',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Assists</h2>,
        selector: 'assists',
    },
    {
        title: 'Shots',
        dataIndex: 'shots',
        key: 'shots',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Shots</h2>,
        selector: 'shots',
    },
    {
        title: 'Shot %',
        dataIndex: 'shotPct',
        key: 'shotPct',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Shot %</h2>,
        selector: 'shotPct',
    },
    {
        title: 'Plus/Minus',
        dataIndex: 'plusMinus',
        key: 'plusMinus',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>+/-</h2>,
        selector: 'plusMinus',
        cell: (row) => {console.log(row);return <a>{Number(row.plusMinus) === 0 ? row.plusMinus : (Number(row.plusMinus) > 0 ? <span style={{ color: "green" }}>{row.plusMinus}</span> : <span style={{ color: "red" }}>{row.plusMinus}</span>)}</a>;},
    },
    {
        title: 'PP Goals',
        dataIndex: 'powerPlayGoals',
        key: 'powerPlayGoals',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>PPG</h2>,
        selector: 'powerPlayGoals'
    },
    {
        title: 'Even Goals/60',
        dataIndex: 'evenGoalsPer60',
        key: 'evenGoalsPer60',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Even G/60</h2>,
        selector: 'evenGoalsPer60'
    },
    {
        title: 'Even Points/60',
        dataIndex: 'evenPointsPer60',
        key: 'evenPointsPer60',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Even P/60</h2>,
        selector: 'evenPointsPer60'
    },
    {
        title: 'Hits/60',
        dataIndex: 'hitsPer60',
        key: 'hitsPer60',
        sortable: true,
        name: <h2 style={{color: "#8ff9ad"}}>Hits/60</h2>,
        selector: 'hitsPer60'
    },
    {
        title: "Add to Chart",
        dataIndex: 'addToChart',
        key: 'addToChart',
        sortable: true,
        name: 'Add to Chart',
        selector: 'addToChart'
    }
];


class Players extends Component {
    constructor(props) {
        super(props);
        this.state = {
            players: players.default,
            radarData: {},
            selectedPlayers: []
        };
        console.log(this.state.players);
        this.onPlayerChange = this.onPlayerChange.bind(this);

    }

    componentDidMount() {
        let data = [];
        let playerData = [];
        Object.keys(this.state.players).forEach((player, index) => {
            let thisPlayer = this.state.players[player];
            console.log(thisPlayer);
            let thisPlayerStat = thisPlayer.stats[0].splits[0].stat;
            playerData.push(_.extend({
                key: index,
                name: thisPlayer.name,
                teamColour: thisPlayer.teamColour
            }, thisPlayerStat)
            );
            data.push({
                key: index,
                name: thisPlayer.name,
                points: thisPlayerStat.points,
                games: thisPlayerStat.games,
                goals: thisPlayerStat.goals,
                assists: thisPlayerStat.assists,
                shots: thisPlayerStat.shots,
                shotPct: thisPlayerStat.shotPct,
                plusMinus: thisPlayerStat.plusMinus,
                powerPlayGoals: thisPlayerStat.powerPlayGoals,
                addToChart: <Checkbox id={thisPlayer.name} onChange={(e) => {
                    let selectedPlayers = this.state.selectedPlayers;
                    debugger
                    if (e.target.checked) {
                        this.setState({ selectedPlayers: selectedPlayers.concat(e.target.id) });
                        this.onPlayerChange(selectedPlayers.concat(e.target.id));
                    }
                    else {
                        this.setState({ selectedPlayers: selectedPlayers.filter(p => p !== e.target.id) });
                        this.onPlayerChange(selectedPlayers.filter(p => p !== e.target.id));
                    }

                }} />


            });
        });
      
        playerData.forEach((player, index) => {
            fields.forEach((field) => {
                console.log(field);
                field.calculate(player);
                data[index][field.fieldName] = player[field.fieldName];
            })
        });
        console.log(playerData);

        this.setState({ tableData: data, playerData });
    }
    onPlayerChange(players) {
        console.log(this.state.selectedPlayers);
        const radarData = {
            labels: radarLabels,
            datasets: this.state.playerData.filter(data => players.includes(data.name)).map((player) => {
                console.log(player);
                return {
                    label: player.name,
                    backgroundColor: player.teamColour,
                    borderColor: 'rgba(179,181,198,1)',
                    pointBackgroundColor: 'rgba(179,181,198,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: [player.evenPointsPer60, player.evenGoalsPer60, player.gameWinningGoals, player.hitsPer60, player.powerPlayGoals]
                }
            })
        };

        this.setState({ radarData });
    }
    render() {
        return (<div className="stat-font">
            {/* <Table style={{ background: "#282c34" }} columns={columns} dataSource={this.state.tableData} />        */}
            <DataTable
                columns={columns}
                data={this.state.tableData}
                theme="dark"
            />

            <Radar data={this.state.radarData} />
        </div>);
    }
}

export default Players;